import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>UDFORSK VORES FORSKELLIGE PRODUKTER</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkter/',
    label: 'SE ALLE PRODUKTER',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul> <li><sup>*</sup>Fastgroet snavs</li> <li><sup>**</sup>vs Neophos Power</li> <li><sup>***</sup>Klarer fastbrændte pletter</li> <li><sup>****</sup>Ingredienser: Enzymer</li> </ul>',
      rows: [
        {
          text: 'Daglig opvask',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea87c4c628b79c83/672b332d92fc2e334ae4afc0/clean.svg',
        },
        {
          text: 'Polerer',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: '48hr indtørrede madrester<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta2d07ab69b81d7e0/672b332e385a9f306c89a14c/dried-on.svg',
        },
        {
          text: 'Hård mod fedt',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4e78d00db65bd109/672b332ef9f8c98e1f5cd550/tough-on-grease.svg',
        },
        {
          text: 'Hurtig opløsning',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltce0e76ec7ad8c2ff/672b332ee26ed7180f4b15a8/fast-dissolving.svg',
        },
        {
          text: 'Mindre kemisk indhold<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4f5999a44f766119/672b332ea9c28979b0f0865e/less-chemical-weight.svg',
        },
        {
          text: 'Glasbeskyttelse',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: 'Friskheds boost',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6bce8566a25bce5a/672b332eaf4968d9c2937157/freshness-boost.svg',
        },
        {
          text: 'Fastbrændte pletter***',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte051fb1e44ab0838/672b332ef082aeb9a04ebbfb/burnt-on-stains.svg',
        },
        {
          text: 'Frigiver rette ingrediens på rette tidspunkt<sup>****</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt80da07055c78fedf/672b332ea688993417c53602/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt50f80564d5968b80/672b2b6abe9e1442566253e1/ultimate-plus-diff-group.webp',
          productName: 'NEOPHOS ULTIMATE PLUS',
          productLink: '/produkter/opvaskemidler/neophos-ultimate-plus/45/',
          color: 'gold-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt01745c3a27055727/672b2b6abdbdf2e6abf2b6e8/ultimate-diff-group.webp',
          productName: 'NEOPHOS ULTIMATE',
          productLink: '/produkter/opvaskemidler/quantum-ultimate-opvasketabletter/48/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt0a1f3dc0a1ba1f4c/672b2b6a497ddff4a033f6b7/quantum-diff-group.webp',
          productName: 'NEOPHOS QUANTUM',
          productLink: '/produkter/opvaskemidler/quantum-max-opvasketabletter/50/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt924952c107639a1b/672b2b6ab11decfa66e16c84/power-diff-group.webp',
          productName: 'NEOPHOS POWER',
          productLink: '/produkter/opvaskemidler/all-in-1-max-opvasketabletter/85/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'NEOPHOS ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte195ec12565832c2/672b2b74a04bd94bedc2de09/neo48.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkter/opvaskemidler/quantum-ultimate-opvasketabletter/48/',
        text: 'UDFORSK ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'HÅRD MOD FEDT',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd91d514e360acde8/672b2b6aa714b7238e595eb2/dried-on-stains.webp',
          name: '<strong>48</strong>HR INDTØRREDE MADRESTER<sup>*</sup>',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'NEOPHOS ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc86365237d26e912/672b2b7484fe89df65061e8d/neogold.webp',
      cardStyle: 'black',
      button: {
        link: '/produkter/opvaskemidler/neophos-ultimate-plus/45/',
        text: 'UDFORSK VORES BEDSTE TAB, ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt116ceb8c905face0/672b2b6a07e8b65425b5cb3d/extra-cleaning-power.webp',
          name: 'HÅRD MOD FEDT',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd2797c2b7eca1490/672b2b6ad58fe80ac644cb9d/tough-on-grease-gold.webp',
          name: '<strong>48</strong>HR INDTØRREDE MADRESTER<sup>*</sup>',
          styleType: 'text-on-image',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt56b7c2f9ec89726f/672b2b6a570b83949bb5c91b/burnt-on-stains.webp',
          name: '<strong>3X</strong> EKSTRA RENGØRINGSKRAFT',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'NEOPHOS QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt291cca14e2aceaeb/672b2b742f2a98412a519de6/neo50.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkter/opvaskemidler/quantum-max-opvasketabletter/50/',
        text: 'UDFORSK QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'HÅRD MOD FEDT',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
